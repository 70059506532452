<template>
  <d-card
    v-if="allDataLoaded"
    class="mt-2">
    <d-card-header class="h6 px-3 mb-0">
      <d-row>
        <d-col :md="userIsOffice ? 10 : 3">
          <span class="d-inline-block">Zdobienie #{{ serviceIndex + 1 }}</span>
        </d-col>
        <d-col
          v-if="!userIsOffice"
          md="2"
          class="text-primary">
          <small>Cena za sztukę: <strong>{{ roundProperly(service.price) }} zł</strong></small>
        </d-col>
        <d-col
          v-if="!userIsOffice"
          md="2"
          class="text-primary">
          <small>Koszt setup: <strong>{{ roundProperly(service.price_setup) }} zł</strong></small>
        </d-col>
        <d-col
          v-if="!userIsOffice"
          md="3"
          class="text-primary">
          <small>Koszt całkowity: <strong>{{ serviceTotalCost }} zł</strong></small>
        </d-col>
        <d-col md="2">
          <d-button
            v-if="isProductSetup && !isQAOnly"
            theme="danger"
            outline
            class="ml-auto d-block"
            :disabled="isBlocked"
            @click.prevent="removeService()">
            <i class="material-icons">delete</i>
            Usuń
          </d-button>
        </d-col>
      </d-row>
    </d-card-header>
    <d-card-body class="px-2 px-lg-3 py-3 py-lg-4">
      <d-alert
        v-if="!!service.deleted_in_hpl"
        show
        theme="danger"
        class="mb-4">
        To zdobienie zostało usunięte w zamówieniu HPL.
      </d-alert>
      <div class="product-info">
        <d-row class="mt-2">
          <d-col sm="6">
            <div class="form-group">
              <label :for="'service-group-' + productIndex + '-' + serviceIndex">
                Zdobienie:
              </label>
              <validation-provider
                :name="'service-group-' + productIndex + '-' + serviceIndex"
                rules="required"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    :disabled="isBlocked || isQAOnly || !isProductSetup"
                    :options="allServiceGroups"
                    v-model="service.service_group_id"
                    :reduce="group => group.id"
                    placeholder="Wybierz zdobienie"
                    @input="resetSelectedVariants"
                    label="name">
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
          <d-col sm="6">
            <div class="form-group">
              <label :for="'service-variant-' + productIndex + '-' + serviceIndex">
                Wybierz wariant zdobienia:
              </label>

              <validation-provider
                :name="'service-variant-' + productIndex + '-' + serviceIndex"
                rules="required"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    :disabled="isBlocked || isQAOnly || !isProductSetup"
                    :options="allServicesFromGroup"
                    @input="getSelectedServicePrices"
                    @change="getSelectedServicePrices"
                    v-model="service.service_id"
                    :reduce="(service) => service.id"
                    placeholder="Wybierz wariant zdobienia"
                    label="name">
                    <template slot="no-options">
                      Brak zdobień w wybranej grupie
                    </template>
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
        </d-row>
        <d-row class="mt-1">
          <d-col sm="4">
            <div class="form-group">
              <label :for="'service-embroidery-type-' + productIndex + '-' + serviceIndex">
                Rodzaj haftu:
              </label>

              <validation-provider
                :name="'service-embroidery-type-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    :disabled="isBlocked || isQAOnly || !isProductSetup"
                    :options="allEmbroideryTypes"
                    v-model="service.embroidery_type_id"
                    :reduce="(type) => type.id"
                    placeholder="Wybierz rodzaj haftu"
                    label="name">
                  </v-select>
                </div>
              </validation-provider>
            </div>
          </d-col>

          <d-col sm="4">
            <div class="form-group">
              <label :for="'service-embroidery-position-' + productIndex + '-' + serviceIndex">
                Pozycja dekoracji:
              </label>

              <validation-provider
                :name="'service-embroidery-position-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    :disabled="isBlocked || isQAOnly || !isProductSetup"
                    :options="allEmbroideryPositions"
                    v-model="service.embroideryPositionNameVal"
                    :reduce="(position) => position.name"
                    placeholder="Wybierz pozycję"
                    :searchable="true"
                    :multiple="false"
                    label="name">
                    <template slot="no-options">
                      Brak takiej pozycji&hellip;
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                </div>
              </validation-provider>
            </div>
          </d-col>

          <d-col sm="4">
            <div class="form-group">
              <label :for="'service-embroidery-color-' + productIndex + '-' + serviceIndex">
                Kolor haftu:
              </label>

              <validation-provider
                :name="'service-embroidery-color-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <d-input
                  v-model="service.embroidery_color"
                  :disabled="isBlocked || isQAOnly || !isProductSetup"
                  :state="errors.length ? 'invalid' : null"/>
              </validation-provider>
            </div>
          </d-col>
        </d-row>

        <d-row class="mt-1">
          <d-col sm="6">
            <div class="form-group">
              <label :for="'service-embroidery-height-' + productIndex + '-' + serviceIndex">
                Wysokość haftu:
              </label>

              <validation-provider
                :rules="service.embroidery_width ? '' : 'required'"
                :name="'service-embroidery-height-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <d-input
                  v-model="service.embroidery_height"
                  :disabled="isBlocked || isQAOnly || !isProductSetup"
                  :state="errors.length ? 'invalid' : null"
                  type="number"/>
                <div class="invalid-feedback">
                  Wymagana szerokość lub wysokość
                </div>
              </validation-provider>
            </div>
          </d-col>

          <d-col sm="6">
            <div class="form-group">
              <label :for="'service-embroidery-width-' + productIndex + '-' + serviceIndex">
                Szerokość haftu:
              </label>

              <validation-provider
                :rules="service.embroidery_height ? '' : 'required'"
                :name="'service-embroidery-width-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <d-input
                  v-model="service.embroidery_width"
                  :disabled="isBlocked || isQAOnly || !isProductSetup"
                  :state="errors.length ? 'invalid' : null"
                  type="number"/>
                <div class="invalid-feedback">
                  Wymagana szerokość lub wysokość
                </div>
              </validation-provider>
            </div>
          </d-col>
        </d-row>

        <d-row class="mt-1">
          <d-col sm="6">
            <div class="form-group">
              <label :for="'service-quantity-' + productIndex + '-' + serviceIndex">
                Liczba sztuk:
              </label>

              <validation-provider
                :rules="'required|only-natural-numbers|min_value:1|max_value:' + productQuantity"
                :name="'service-quantity-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <d-input-group
                  append="szt."
                  :class="{'is-invalid': errors.length}">
                  <d-input
                    type="number"
                    step="1"
                    min="0"
                    :max="productQuantity"
                    :disabled="isBlocked || isQAOnly || !isProductSetup"
                    @change="getSelectedServicePrices"
                    class="text-right"
                    v-model="service.quantity"
                    :state="errors.length ? 'invalid' : null"/>
                </d-input-group>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>

          <d-col sm="6">
            <div class="form-group">
              <label :for="'service-dst-' + productIndex + '-' + serviceIndex">
                DST:
              </label>
              <validation-provider
                :name="'service-dst-' + productIndex + '-' + serviceIndex"
                rules="required"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    :disabled="isBlocked || isQAOnly || !isProductSetup"
                    :options="dstOptions"
                    v-model="service.dst"
                    :reduce="dst => dst.value"
                    placeholder="Wybierz DST"
                    label="text">
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
        </d-row>
      </div>
      <hr>
      <div class="pb-4">
        <d-row class="mt-2">
          <d-col sm="6">
            <div class="form-group">
              <label :for="'service-number-dst-' + productIndex + '-' + serviceIndex">
                Numer DST:
              </label>

              <validation-provider
                :name="'service-number-dst-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <d-input
                  :disabled="isBlocked || isQAOnly || dstAccepted"
                  :state="errors.length ? 'invalid' : null"
                  v-model="service.dst_number" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
          <d-col sm="6">
            <div class="form-group">
              <label :for="'service-contractor-dst-' + productIndex + '-' + serviceIndex">
                Wykonawca DST:
              </label>

              <validation-provider
                :name="'service-contractor-dst-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    :disabled="isBlocked || isQAOnly || dstAccepted"
                    :options="allContractors"
                    v-model="service.contractor_id"
                    :reduce="(contractor) => contractor.id"
                    placeholder="Wybierz wykonawcę DST"
                    :searchable="true"
                    label="name">
                    <template slot="no-options">
                      Brak pasujących rezultatów
                    </template>
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
        </d-row>
        <d-row v-if="service.dst === 'CUSTOMER' || service.dst === 'ORDER'">
          <d-col
            v-if="service.dst === 'CUSTOMER'"
            sm="4">
            <div class="form-group">
              <label :for="'service-client-disc-' + productIndex + '-' + serviceIndex">
                Dysk klienta:
              </label>
              <d-checkbox
                :disabled="isBlocked || isQAOnly || dstAccepted"
                toggle
                toggle-small
                class="mr-0 d-block"
                inline
                v-model="service.dst_disc"/>
            </div>
          </d-col>
          <d-col
            v-if="service.dst === 'ORDER'"
            sm="4">
            <div class="form-group">
              <label :for="'dst-receive-date' + productIndex + '-' + serviceIndex">
                Data otrzymania DST:
              </label>
              <validation-provider
                :name="'dst-receive-date' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-date-range': true,
                    'vs-wrap-text': true,
                    'is-invalid': errors.length,
                    'is-disabled': isBlocked || isQAOnly || dstAccepted
                  }">
                  <date-range-picker
                    ref="picker"
                    :locale-data="{
                      'firstDay': 1,
                      'format': 'DD-MM-YYYY',
                      'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                      'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                      'applyLabel': 'OK',
                      'cancelLabel': 'Anuluj'
                    }"
                    :class="{ 'w-100': true, 'is-disabled': isQAOnly || dstAccepted }"
                    :disabled="isBlocked || isQAOnly || dstAccepted"
                    :singleDatePicker="true"
                    :timePicker="false"
                    :showWeekNumbers="false"
                    :showDropdowns="false"
                    v-model="service.dstReceiveDate"
                    :ranges="false"
                    :linkedCalendars="true"
                    :date-format="dateFormat"
                    :auto-apply="true"
                    @update="updateDstReceiveDates">
                    <template v-slot:input="picker">
                      {{ prepareDateString(picker.startDate) }}
                    </template>
                  </date-range-picker>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
          <d-col
            v-if="service.dst === 'ORDER'"
            sm="4">
            <div class="form-group">
              <label :for="'service-client-disc-' + productIndex + '-' + serviceIndex">
                Rozliczono z dostawcą:
              </label>
              <d-checkbox
                :disabled="isBlocked || isQAOnly"
                toggle
                toggle-small
                class="mr-0 d-block"
                inline
                v-model="service.dst_settled"/>
            </div>
          </d-col>
        </d-row>
        <d-row class="mt-1">
          <d-col sm="4">
            <div class="form-group">
              <label :for="'service-stitch-number-' + productIndex + '-' + serviceIndex">
                Liczba ściegów:
              </label>

              <validation-provider
                rules="only-natural-numbers"
                :name="'service-stitch-number-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <d-input
                  :disabled="isBlocked || isQAOnly || dstAccepted"
                  class="text-right"
                  type="number"
                  :min="1"
                  :step="1"
                  :state="errors.length ? 'invalid' : null"
                  v-model="service.stitch_number" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
          <d-col sm="8">
            <div class="form-group">
              <label :for="'service-real-' + productIndex + '-' + serviceIndex">
                Prawdziwy wariant zdobienia:
              </label>

              <validation-provider
                :name="'service-real-' + productIndex + '-' + serviceIndex"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    :disabled="isBlocked || isQAOnly || dstAccepted"
                    :options="allServicesFromGroup"
                    v-model="service.real_service_id"
                    :reduce="(service) => service.id"
                    placeholder="Wybierz prawdziwy wariant zdobienia"
                    :searchable="true"
                    label="name">
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
        </d-row>
        <d-row class="mt-1">
          <d-col cols="12">
            <strand-select
              :ref="'strand-select'"
              :all-strands="allStrands"
              :current-strands="service.strands"
              :is-disabled="isBlocked || isQAOnly || dstAccepted"
              :is-read-only="dstAccepted"
              :index="`${productIndex}-${serviceIndex}`" />
          </d-col>
        </d-row>
        <small
          v-if="status === null"
          class="text-secondary">
          Uzupełnienie powyższej grupy pól będzie możliwe po zapisaniu zamówienia.
        </small>

        <hr>

        <d-row>
          <d-col sm="12">
            <div class="form-group">
              <label for="description">
                Uwagi:
              </label>
              <d-textarea
                name="description"
                v-model="service.description"
                :disabled="isBlocked"
                :value.prop="service.description"
                :rows="2"/>
            </div>
          </d-col>
        </d-row>
      </div>

      <files-list
        v-if="service.files && service.files.length"
        :files="service.files"
        class="mb-4" />

      <d-tabs card >
        <!-- Próby zdobienia -->
        <d-tab
          title="Próby zdobienia"
          :active="isInPreparation"
          class="px-2">
          <d-row class="mb-0 pb-2 border-bottom d-flex justify-content-between align-items-center">
            <d-col
              sm="12"
              md="9"
              lg="9">
              <h5 class="my-0">Próby zdobienia</h5>
            </d-col>
            <d-col
              sm="12"
              md="3"
              lg="3">
              <d-button
                :disabled="isBlocked || !isSamplesOnly || serviceSamplesApproved"
                theme="success"
                class="d-block ml-auto my-auto"
                @click.prevent="$refs['order-creator-service-samples'].addSample()">
                <i class="material-icons">add</i>
                Dodaj próbę
              </d-button>
            </d-col>
          </d-row>

          <order-creator-service-samples
            v-if="!isProductSetup"
            ref="order-creator-service-samples"
            :key="'sample-' + service.id"
            :samples="service.samples"
            :productIndex="productIndex"
            :serviceIndex="serviceIndex"
            :allStrands="allStrands"
            :isReadOnly="isBlocked || !isSamplesOnly"/>
          <span
            v-else
            class="d-block pt-4">
            Dodawanie prób będzie możliwie po zaakceptowaniu DST.
          </span>
        </d-tab>
        <!-- Maszyny realizujące zdobienie -->
        <d-tab
          title="Maszyny realizujące zdobienie"
          :active="!isInPreparation"
          class="px-2">
          <d-row class="mb-0 pb-2 border-bottom d-flex justify-content-between align-items-center">
            <d-col
              sm="12"
              md="8"
              lg="8">
              <h5 class="my-0">Maszyny realizujące zdobienie</h5>
            </d-col>
            <d-col
              col
              sm="12"
              md="4"
              lg="4">
              <d-button
                :disabled="isBlocked || toBeDelivered || (!isMachinesEnabled) || (!service.samples.find(sample => sample.status === 'APPROVED')) || isAllQuantityAssigned"
                class="d-block ml-auto my-auto"
                theme="success"
                @click.prevent="$refs['order-creator-service-machine'].addMachine(service.quantity)">
                <i class="material-icons">add</i>
                Dodaj maszynę
              </d-button>
            </d-col>
          </d-row>
          <div
            v-if="conditionsFoMachinesNotAssignedCount && machinesNotAssignedCount">
            <d-alert
              theme="warning"
              show
              class="mt-2 mb-0">
              W wyniku kontroli zostało domówionych <strong>{{ rejectedCount }}</strong> produktów z czego <strong>{{ machinesNotAssignedCount }}</strong> nie jest przypisanych do maszyn. Przypisz produkty aby kontynuować produkcję.
            </d-alert>
            <d-button
              class="d-block ml-auto my-3"
              theme="success"
              :disabled="isBlocked"
              @click.prevent="acceptDifference">
              Akceptuj różnicę
            </d-button>
          </div>

          <order-creator-service-machine
            v-if="service.samples.find(sample => sample.status === 'APPROVED')"
            :key="'machine-' + service.id"
            :ref="'order-creator-service-machine'"
            :allMachines="allMachines"
            :allWorkers="allWorkers"
            :differenceAccepted="service.difference_accepted"
            :isReadOnly="isBlocked || (!isMachinesEnabled) || (status === 'WAITING_ADDITIONAL_PRODUCTS' && toBeDelivered)"
            :isReorder="rejectedCount > 0"
            :machines="service.machines"
            :orderName="orderName"
            :orderServiceID="service.id"
            :productCode="productCode"
            :rejectedCount="rejectedCount"
            :serviceID="service.service_id"
            :serviceName="service.service_name"
            :serviceQuantity="service.quantity" />
          <span
            v-else
            class="d-block pt-4">
            Dodawanie maszyny będzie możliwie po zaakceptowaniu próby.
          </span>
        </d-tab>
      </d-tabs>
    </d-card-body>
  </d-card>
</template>

<script>
import Vue from 'vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import CustomDecimals from '@/utils/CustomDecimals.js';
import UniversalUtils from '@/utils/UniversalUtils.js';
import OrderCreatorServiceSamples from './OrderCreatorServiceSamples.vue';
import OrderCreatorServiceMachine from './OrderCreatorServiceMachine.vue';
import StrandSelect from '@/components/forms/order/StrandSelect.vue';
import DstTypes from '@/data/dst-types.js';
import FilesList from '@/components/forms/order/FilesList.vue';

export default {
  name: 'order-creator-service',
  props: [
    'allContractors',
    'allEmbroideryPositions',
    'allEmbroideryTypes',
    'allMachines',
    'allPrices',
    'allServiceGroups',
    'allServices',
    'allStrands',
    'allWorkers',
    'initialService',
    'isBlocked',
    'isInPreparation',
    'isMachinesEnabled',
    'isProductSetup',
    'isQAOnly',
    'isSamplesOnly',
    'orderName',
    'performPriceRecalculate',
    'productCode',
    'productIndex',
    'productQuantity',
    'rejectedCount',
    'serviceIndex',
    'status',
    'toBeDelivered'
  ],
  components: {
    'date-range-picker': DateRangePicker,
    'order-creator-service-samples': OrderCreatorServiceSamples,
    'order-creator-service-machine': OrderCreatorServiceMachine,
    'strand-select': StrandSelect,
    'files-list': FilesList
  },
  watch: {
    serviceTotalCost (newValue) {
      this.$emit('updateServicePrice', this.serviceIndex, newValue);
    },
    conditionsFoMachinesNotAssignedCount (newVal) {
      if (!newVal) {
        return;
      }

      Vue.nextTick(() => {
        this.setMachinesNotAssignedCount();
      })
    }
  },
  computed: {
    allDataLoaded () {
      return this.dataLoaded;
    },
    userIsOffice () {
      return this.$store.getters['getUserType'] === 'OFFICE';
    },
    dstOptions () {
      return DstTypes;
    },
    clientDiskOptions () {
      return [
        {
          text: 'Tak',
          value: 1
        },
        {
          text: 'Nie',
          value: 0
        }
      ];
    },
    dstAccepted () {
      if (this.status === 'NEW' || this.status === 'WAITING_PRODUCTS' || this.status === 'WAITING_DST') {
        return false;
      }

      return true;
    },
    availableStrandColors () {
      if (this.allStrands) {
        return this.allStrands.map(strand => strand.name);
      }

      return [];
    },
    allServicesFromGroup () {
      let servicesFromGroup = this.allServices.filter(item => item.group_id === this.service.service_group_id);

      return servicesFromGroup;
    },
    serviceTotalCost () {
      let quantity = Number(this.service.quantity);
      let totalPrice = 0;

      if (quantity >= Number(this.service.price_multiple_from)) {
        totalPrice = ((Number(this.service.price) * quantity) + Number(this.service.price_setup));
      } else {
        totalPrice = (Number(this.service.price) + Number(this.service.price_setup));
      }

      return this.roundProperly(totalPrice);
    },
    serviceSamplesApproved () {
      return this.service.samples.filter(sample => sample.status === 'APPROVED').length > 0;
    },
    isAllQuantityAssigned () {
      let rejected = this.rejectedCount ? this.rejectedCount : 0;
      let quantityAssigned = 0;
      quantityAssigned += this.service.machines.reduce((sum, item) => sum + Number(item.quantity), 0);

      return quantityAssigned === (this.service.quantity + rejected);
    },
    conditionsFoMachinesNotAssignedCount () {
      if (!this.allDataLoaded || this.toBeDelivered || this.service.difference_accepted) {
        return false;
      }

      if (!(this.status === 'WAITING_ADDITIONAL_PRODUCTS' || this.status === 'IN_REALIZATION')) {
        return false;
      }

      if (!this.service.samples.find(sample => sample.status === 'APPROVED')) {
        return false;
      }

      return true;
    }
  },
  data () {
    return {
      acceptDifferenceInProgress: false,
      dataLoaded: false,
      machinesNotAssignedCount: 0,
      service: {}
    };
  },
  mounted () {
    this.service = JSON.parse(JSON.stringify(this.initialService));
    this.service.dst_disc = !!this.service.dst_disc;
    this.service.dst_settled = !!this.service.dst_settled;
    this.service.reorderAssignedCount = 0;
    this.service.dstReceiveDate = {
      startDate: this.service.dst_receive_date ? new Date(this.service.dst_receive_date) : null
    };

    if (this.service.embroidery_position_id && this.service.embroidery_position_name) {
      this.service.embroideryPositionNameVal = this.service.embroidery_position_name;
    } else if (this.service.embroidery_position) {
      this.service.embroideryPositionNameVal = this.service.embroidery_position;
    }

    if (this.service.samples) {
      this.service.samples.forEach(item => {
        item.uui = UniversalUtils.generateUUID();
      });
    }

    if (this.isProductSetup && !this.isQAOnly && this.performPriceRecalculate) {
      this.getSelectedServicePrices();
    }

    Vue.nextTick(() => {
      this.dataLoaded = true;
    })
  },
  methods: {
    getService () {
      if (this.$refs['strand-select']) {
        this.service.strands = this.$refs['strand-select'].getStrands();
      }
      if (this.$refs['order-creator-service-samples']) {
        this.service.samples = this.$refs['order-creator-service-samples'].getSamples();
      }
      if (this.$refs['order-creator-service-machine']) {
        this.service.machines = this.$refs['order-creator-service-machine'].getMachines();
      }
      return JSON.parse(JSON.stringify(this.service));
    },
    getServiceForAPI () {
      let serviceToSave = {
        id: this.service.id,
        contractor_id: this.service.contractor_id,
        description: this.service.description,
        dst: this.service.dst,
        dst_disc: this.service.dst_disc ? 1 : 0,
        dst_number: this.service.dst_number,
        dst_receive_date: this.service.dst_receive_date,
        dst_settled: this.service.dst_settled ? 1 : 0,
        embroidery_color: this.service.embroidery_color,
        embroidery_height: Number(this.service.embroidery_height),
        embroidery_position: this.service.null,
        embroidery_position_id: 0,
        embroidery_position_name: null,
        embroidery_type_id: this.service.embroidery_type_id,
        embroidery_width: Number(this.service.embroidery_width),
        machines: [],
        quantity: parseFloat(this.service.quantity),
        samples: [],
        service_group_id: this.service.service_group_id,
        service_id: this.service.service_id,
        stitch_number: this.service.stitch_number,
        strands: this.service.strands
      };

      if (this.service.embroideryPositionNameVal) {
        let existingPosition = this.allEmbroideryPositions.find(item => item.name === this.service.embroideryPositionNameVal);

        if (existingPosition) {
          serviceToSave.embroidery_position_id = existingPosition.id;
          serviceToSave.embroidery_position_name = existingPosition.name;
        } else {
          serviceToSave.embroidery_position = this.service.embroideryPositionNameVal;
        }
      }

      if (this.$refs['strand-select']) {
        serviceToSave.strands = this.$refs['strand-select'].getStrands();
      }

      if (this.isProductSetup) {
        serviceToSave.price_multiple_from = Number(this.roundProperly(this.service.price_multiple_from));
      }

      serviceToSave.price = Number(this.roundProperly(this.service.price));
      serviceToSave.price_setup = Number(this.roundProperly(this.service.price_setup));

      if (('real_service_id' in this.service) && (this.service.real_service_id !== 0)) {
        serviceToSave.real_service_id = this.service.real_service_id;
      }

      if (this.$refs['order-creator-service-samples']) {
        serviceToSave.samples = this.$refs['order-creator-service-samples'].getSamples();
      }
      if (this.$refs['order-creator-service-machine']) {
        serviceToSave.machines = this.$refs['order-creator-service-machine'].getMachines();
      }

      return serviceToSave;
    },
    removeService () {
      this.$emit('removeService', this.serviceIndex);
    },
    resetSelectedVariants () {
      this.service.service_id = null;
    },
    acceptDifference () {
      this.acceptDifferenceInProgress = true;

      this.$http.request({
        method: 'POST',
        url: '/api/orders/services/set-difference-accepted/' + this.service.id,
        headers: { 'Content-Type': undefined },
      }).then(() => {
        this.acceptDifferenceInProgress = false;
        Vue.set(this.service, 'difference_accepted', true);
        this.$bus.$emit('order-save');
      }).catch(error => {
        console.log(error);
        Vue.swal({
          title: 'Wystąpił błąd podczas zapisu',
          html: 'Spróbuj ponownie',
          icon: 'warning',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });
        this.acceptDifferenceInProgress = false;
      });
    },
    setMachinesNotAssignedCount () {
      if (!this.$refs['order-creator-service-machine']) {
        this.machinesNotAssignedCount = 0;
        return;
      }

      this.machinesNotAssignedCount = this.$refs['order-creator-service-machine'].getNotAssignedCount();
    },
    getSelectedServicePrices () {
      if (this.service.service_id) {
        let serviceID = this.service.service_id;
        this.service.price_multiple_from = this.allServices.find(item => item.id === serviceID).price_multiple_from;

        let quantity = Number(this.service.quantity);
        let servicePrices = this.allPrices.filter(price => price.service_id === serviceID && !price.is_setup);
        let serviceSetupPrices = this.allPrices.filter(price => price.service_id === serviceID && price.is_setup);

        servicePrices.sort((priceA, priceB) => priceB.value_from - priceA.value_from);
        let priceIndexToUse = servicePrices.findIndex(item => item.value_from <= quantity);

        if (priceIndexToUse > -1) {
          Vue.set(this.service, 'price', servicePrices[priceIndexToUse].price);
        } else {
          Vue.set(this.service, 'price', 0);
        }

        serviceSetupPrices.sort((priceA, priceB) => priceA.value_from - priceB.value_from);
        let setupPriceIndexToUse = serviceSetupPrices.findIndex(item => item.value_from >= quantity);

        if (setupPriceIndexToUse > -1) {
          Vue.set(this.service, 'price_setup', serviceSetupPrices[setupPriceIndexToUse].price);
        } else {
          Vue.set(this.service, 'price_setup', 0);
        }
      } else {
        Vue.set(this.service, 'price_setup', 0);
        Vue.set(this.service, 'price', 0);
      }
    },
    updateDstReceiveDates () {
      if (this.service.dstReceiveDate && this.service.dstReceiveDate.startDate) {
        this.service.dst_receive_date = this.prepareDateString(this.service.dstReceiveDate.startDate);
      } else {
        this.service.dst_receive_date = null;
      }
    },
    roundProperly (value, decimals = 2) {
      return CustomDecimals.roundProperly(value, decimals);
    },
    prepareDateString (input) {
      if (input === null) {
        return 'Wybierz datę';
      }
      input = new Date(input);

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    dateFormat (classes) {
      if (!classes.disabled) {
        classes.disabled = false;
      }
      return classes
    }
  }
};
</script>

<style lang="scss">
.order-creator-service {
  .is-disabled {
    background: #eee;
    cursor: not-allowed;
  }

  .form-control.is-date-range .vue-daterange-picker.is-disabled .reportrange-text {
    background: #f5f6f7!important;
    pointer-events: none;
  }
}
</style>
