<template>
  <d-card>
    <d-card-body class="px-2 px-lg-3 py-3 py-lg-4">
      <d-row>
        <d-col sm="12" md="6" lg="3">
          <div class="form-group">
            <label>
              Kod produktu:
            </label>
            <div class="text-primary">
              {{ product.product_code }}
            </div>
          </div>
        </d-col>
        <d-col sm="12" md="6" lg="3">
          <div class="form-group">
            <label>
              Kolor:
            </label>
            <div class="text-primary">
              {{ product.color_name || '-' }}
            </div>
          </div>
        </d-col>
        <d-col sm="12" md="6" lg="3">
          <div
            v-if="isInQa"
            class="form-group">
            <label>
              Pozostało do skontrolowania:
            </label>
            <div class="text-primary">
              {{leftToControlCount }} szt. (z {{ product.quantity }} szt.)
            </div>
          </div>
          <div
            v-else
            class="form-group">
            <label>
              Liczba sztuk:
            </label>
            <div class="text-primary">
              {{ product.quantity }} szt.
            </div>
          </div>
        </d-col>
        <d-col sm="12" md="6" lg="3">
          <div
            v-if="isInQa"
            class="form-group">
            <label>
              Wyniki dotychczasowych kontroli:
            </label>
            <div>
              <span class="text-primary">
                poprawne: {{ getCorrectAmount() }} szt.
              </span><br>
              <span class="text-danger">
                odrzucone: {{ getRejectedAmount() }} szt.
              </span>
            </div>
          </div>
          <div
            v-else
            class="form-group">
            <label>
              Ostateczny termin realizacji:
            </label>
            <div>
              <span :class="isAfterDeadline ? 'text-danger' : 'text-primary'">
                {{ formatDateString(orderDeadline) }}
              </span>
            </div>
          </div>
        </d-col>
      </d-row>

      <h6 class="mb-3 py-3 border-bottom">
        Zdobienia:
      </h6>
      <d-card
        v-for="(service, indexS) of product.services"
        :key="'service-item-' + indexS"
        :class="indexS > 0 ? 'mt-4' : 'mt-2'">
        <d-card-body class="px-2 px-lg-3 py-3 py-lg-4">
          <d-row>
            <d-col sm="4">
              <div class="form-group">
                <label>
                  Zdobienie:
                </label>
                <div class="text-primary">
                  {{ service.service_name }}
                </div>
              </div>
            </d-col>
            <d-col sm="4">
              <div class="form-group">
                <label>
                  Prawdziwy wariant zdobienia:
                </label>
                <div class="text-primary">
                  <template v-if="service.real_service_name">
                    {{ service.real_service_name }}
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </div>
            </d-col>
            <d-col sm="4">
              <div class="form-group">
                <label>
                  Grupa zdobień:
                </label>
                <div class="text-primary">
                  {{ service.service_group_name || '-' }}
                </div>
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col sm="4">
              <div class="form-group">
                <label>
                  Numer DST:
                </label>
                <div>
                  <template v-if="getDstNumber(service)">
                    <span class="text-primary">
                      {{ getDstNumber(service) }}
                    </span>
                  </template>
                  <template v-else>
                    brak
                  </template>
                </div>
              </div>
            </d-col>
            <d-col sm="4">
              <div class="form-group">
                <label>
                  Liczba ściegów:
                </label>

                <div>
                  <template v-if="getStitchNumber(service)">
                    <span class="text-primary">
                      {{ getStitchNumber(service) }}
                    </span>
                  </template>
                  <template v-else>
                    brak
                  </template>
                </div>
              </div>
            </d-col>
            <d-col sm="4">
              <div class="form-group">
                <label>
                  Kolory nici:
                </label>
                <div>
                  <template v-if="getStrands(service)">
                    <span class="text-primary">
                      {{ getStrands(service) }}
                    </span>
                  </template>
                  <template v-else>
                    brak
                  </template>
                </div>
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col sm="4">
              <div class="form-group">
                <label>
                  DST:
                </label>
                <div class="text-primary">
                  {{ getDSTType(service.dst) }}
                </div>
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col sm="4">
              <div class="form-group">
                <label>
                  Uwagi:
                </label>
                <div class="text-primary">
                  {{ service.description ||'-'}}
                </div>
              </div>
            </d-col>
          </d-row>

          <d-row class="mb-3 pt-3 border-bottom">
            <d-col sm="12">
              <h5>Próby zdobienia</h5>
            </d-col>
          </d-row>

          <template v-if="service.samples.length">
            <order-creator-service-samples-info
              ref="order-creator-service-samples-info"
              :samples="service.samples"/>
          </template>
          <template v-else>
            Brak prób dla tego zdobienia.
          </template>
        </d-card-body>
      </d-card>
    </d-card-body>
  </d-card>
</template>

<script>
import ProductTypes from '@/data/product-types.js';
import DstTypes from '@/data/dst-types.js';
import OrderCreatorServiceSamplesInfo from '@/components/forms/order/OrderCreatorServiceSamplesInfo.vue';
import CalendarUtils from '@/utils/CalendarUtils.js';

export default {
  name: 'order-controller-product',
  props: {
    isInQa: {
      default: true,
      type: Boolean
    },
    orderDeadline: {
      default: '',
      required: false,
      type: String
    },
    product: {
      required: true,
      type: Object
    }
  },
  components: {
    'order-creator-service-samples-info': OrderCreatorServiceSamplesInfo
  },
  computed: {
    leftToControlCount () {
      return (this.product.quantity + this.getDeliveredAmount()) - (this.getCorrectAmount() + this.getRejectedAmount());
    },
    productTypeOptions () {
      return ProductTypes;
    },
    dstOptions () {
      return DstTypes;
    }
  },
  methods: {
    formatDateString (arg) {
      return CalendarUtils.formatDateString(arg);
    },
    getDSTType (dstValue) {
      if (!dstValue) {
        return '-';
      }
      let itemWithValue = this.dstOptions.find(dst => dst.value === dstValue);
      if (itemWithValue && itemWithValue.text) {
        return itemWithValue.text;
      }

      return '-';
    },
    getCorrectAmount () {
      return this.product.controls.map(control => control.correct).reduce((sum, control) => sum + control, 0);
    },
    getRejectedAmount () {
      return this.product.controls.map(control => control.rejected).reduce((sum, control) => sum + control, 0);
    },
    getDeliveredAmount () {
      return this.product.controls.map(control => control.delivered).reduce((sum, control) => sum + control, 0);
    },
    getDstNumber (service) {
      let mostRecentDstNumber = service.dst_number;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].dst_number) {
            mostRecentDstNumber = acceptedSamples[i].dst_number;
          }
        }
      }
      return mostRecentDstNumber;
    },
    getStitchNumber (service) {
      let mostRecentStitchNumber = service.stitch_number;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].stitch_number) {
            mostRecentStitchNumber = acceptedSamples[i].stitch_number;
          }
        }
      }
      return mostRecentStitchNumber;
    },
    getStrands (service) {
      let mostRecentStrands = service.strands;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].strands && acceptedSamples[i].strands.length) {
            mostRecentStrands = acceptedSamples[i].strands;
          }
        }
      }

      let strandsString = '';
      for (let i = 0; i < mostRecentStrands.length; i++) {
        strandsString += mostRecentStrands[i].strand_name + ';  ';
      }

      return strandsString;
    },
    isAfterDeadline () {
      return CalendarUtils.getIsDayBeforeDate(this.orderDeadline, new Date());
    }
  }
};
</script>
