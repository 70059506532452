<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4 pb-5">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="9"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Weryfikacja</span>
        <h3 class="page-title">Produkty do skontrolowania</h3>
      </d-col>
      <d-col sm="3" class="align-self-end">
        <time-tracker />
      </d-col>
    </d-row>

    <d-row
      v-if="loaded"
      style="width: 100%;"
      class="d-flex ml-0 mb-0 page-filters">
      <validation-observer
        ref="orders-controller-filters-form"
        tag="form"
        class="d-flex mb-3">
        <d-input
          placeholder="Podaj szukaną frazę"
          v-model="filters.search"
          class="mr-1 mb-2"
          style="min-width: 290px;" />

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-md-auto mr-1 mb-2"
          @click.prevent="validateFiltersForm">Szukaj</d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2"
          @click.prevent="clearFilters">Resetuj filtry</d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%">
      <table class="table mt-0 mb-0 full-width">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-0 align-middle">Produkt</th>
            <th scope="col" class="border-0 align-middle text-center">Rodzaj czapki</th>
            <th scope="col" class="border-0 align-middle text-right">Ilość</th>
            <th scope="col" class="border-0 align-middle text-center">Nazwa zamówienia</th>
            <th scope="col" class="border-0 align-middle text-center">Deadline</th>
            <th scope="col" class="border-0 align-middle text-center" style="width: 250px;">Akcje</th>
          </tr>
        </thead>
        <tbody v-if="loaded">
          <tr v-for="(product, index) of products" :key="'product-' + index">
            <td class="align-middle py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Produkt</label>
              Kod: <strong>{{ product.product_code }}</strong><br>
              <small>Ilość zdobień: <strong>{{ product.services_count }}</strong></small>
            </td>
            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Rodzaj czapki</label>
              {{ getCapType(product.type) }}
            </td>
            <td class="align-middle text-right py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Ilość</label>
              {{ product.quantity + product.control_delivered - product.control_correct - product.control_rejected }} szt.
            </td>

            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Nazwa zamówienia</label>
              {{ product.order.order_name }}
            </td>
            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Deadline</label>
              {{ product.order_deadline }}
            </td>
            <td class="text-center align-middle">
              <template v-if="product.controller_id === 0">
                <d-button
                  style="color: #fff;"
                  theme="warning"
                  @click.native="takeToControl(product.id)"
                  class="mx-2"
                  title="Przyjmij do kontroli">
                  Przyjmij do kontroli
                </d-button>
              </template>
              <template v-else>
                <d-button
                  theme="success"
                  class="mx-2 mt-2"
                  title="Skontroluj"
                  @click.prevent="addControll(product.id)">
                  <i class="material-icons">add</i>
                </d-button>
                <d-button
                  theme="primary"
                  @click.native="$router.push('/produkty-do-skontrolowania/edycja/' + product.id)"
                  class="mx-2 mt-2"
                  title="Zobacz">
                  <i class="material-icons">visibility</i>
                </d-button>
              </template>
            </td>
          </tr>
          <tr v-if="products.length === 0">
            <td colspan="7" class="text-center">
              Brak produktów do skontrolowania&hellip;
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="!loaded" class="pb-3 pt-3 text-center">
        Trwa ładowanie danych&hellip;
      </div>

      <d-alert v-if="loadError" show theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
    </d-card>
    <order-creator-qa-popup />
  </d-container>
</template>

<script>
import Vue from 'vue';
import ListUtils from '@/utils/ListUtils.js';
import FormUtils from '@/utils/FormUtils.js';
import OrderCreatorQualityControlPopup from '@/components/popups/OrderCreatorQualityControlPopup.vue';
import debounce from 'lodash.debounce';
import TimeTracker from '@/components/common/TimeTracker.vue';
import ProductTypes from '@/data/product-types.js';

export default {
  name: 'orders-controller',
  components: {
    'order-creator-qa-popup': OrderCreatorQualityControlPopup,
    'time-tracker': TimeTracker
  },
  computed: {
    productTypeOptions () {
      return ProductTypes;
    }
  },
  data() {
    return {
      products: [],
      loaded: false,
      loadError: false,
      controllerItem: null,
      loadedControllerItem: false,
      filters: {
        search: ''
      }
    };
  },
  mounted() {
    this.loadFilteredData();
    this.$bus.$on('reload-controller-list', this.loadFilteredData);
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let where = {};

      if (this.filters.search) {
        where.search = this.filters.search;
      }

      this.loaded = false;
      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/orders/products/controller-items',
        listField: 'products',
        pagination: false,
        params: {
          where
        }
      });
    },
    clearFilters () {
      this.filters = {
        search: ''
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['orders-controller-filters-form'], this.debouncedLoadFilteredData);
    },
    getCapType (capType) {
      if (!capType) {
        return '-';
      }
      let productType = this.productTypeOptions.find(cap => cap.value === capType);
      if (productType && productType.text) {
        return productType.text;
      }

      return '-';
    },
    takeToControl (productID) {
      Vue.swal({
        title: 'Czy na pewno chcesz przyjąć ten produkt do kontroli?',
        text: 'Tej operacji nie można cofnąć',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, przyjmij',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.$http.request({
            method: 'GET',
            url: '/api/orders/products/take-to-control/' + productID,
            headers: { 'Content-Type': undefined },
          }).then(() => {
            Vue.swal({
              title: 'Produkt przyjęty do kontroli',
              html: 'Wybrany produkt został przyjęty do kontroli',
              icon: 'success',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
            this.loadFilteredData();
          }).catch(error => {
            console.log(error);
            Vue.swal({
              title: 'Wystąpił błąd podczas zapisu',
              html: 'Spróbuj ponownie',
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
          });
        }
      });
    },
    addControll(productID) {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/orders/products/controller-item/' + productID,
        method: 'get',
        outputKey: 'controllerItem',
        loadedKey: 'loadedControllerItem',
        errorKey: 'loadError',
        successAction: () => {
          Vue.nextTick(() => {
            this.$bus.$emit('quality-control-popup-show', this.controllerItem, this.controllerItem.order.order_name, [], this.controllerItem.services.length, 'controllerList');
          });
        }
      });
    }
  },
  beforeDestroy () {
    this.$bus.$off('reload-controller-list', this.loadFilteredData);
  }
};
</script>
